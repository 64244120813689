<template>
  <div class="roomssectionhome">
      <b-card-group class="cardgroup">
        <div v-for="items in topRated" :key="items.id" class="singlecard">
          <b-card
            :img-src="items.url"
            img-alt="room image"
            img-top
            class="card-img-top"
          >
            <b-card-title class="cardtitle">
                {{ $t("home.rooms." + items.name + ".title") }}
            </b-card-title>
            <b-card-text>
              <p class="priceroom" style="margin-bottom: 2px;">¡40% {{$t("home.rooms.desc")}}!</p>
              <p>{{ $t("home.rooms." + items.name + ".desc") }}</p>
            </b-card-text>
            <b-button v-on:click="addItem(items)" id="book" :to="{ name: 'Book' }">{{ $t("menu.book") }}</b-button>
          </b-card>
          <!-- <b-card
            :img-src="require(`../../assets/fotos/doble1.jpg`)"
            img-alt="double"
            img-top
            class="xs:mb-4 md:mb-0 xs:mx-0 md:mx-2"
          >
            <b-card-title class="cardtitle">{{
              $t("home.rooms.doble")
            }}</b-card-title>
            <b-card-text>
              <p>{{ $t("home.rooms.dobletext") }}</p>
            </b-card-text>
            <b-button id="book" :to="{ name: 'Book', params: { lang: this.$i18n.locale } }">{{ $t("menu.book") }}</b-button>
          </b-card>
          <b-card
            :img-src="require(`../../assets/fotos/matri1.jpg`)"
            img-alt="matrimonial"
            img-top
            class="xs:mb-4 md:mb-0 xs:mx-0 md:mx-2"
          >
            <b-card-title class="cardtitle">{{
              $t("home.rooms.matri")
            }}</b-card-title>
            <b-card-text>
              <p>{{ $t("home.rooms.matritext") }}</p>
            </b-card-text>
            <b-button id="book" :to="{ name: 'Book', params: { lang: this.$i18n.locale } }">{{ $t("menu.book") }}</b-button>
          </b-card>
          <b-card
            :img-src="require(`../../assets/fotos/triple1.jpg`)"
            img-alt="triple"
            img-top
            class="xs:mb-4 md:mb-0 xs:mx-0 md:mx-2"
          >
            <b-card-title class="cardtitle">{{
              $t("home.rooms.triple")
            }}</b-card-title>
            <b-card-text>
              <p>{{ $t("home.rooms.tripletext") }}</p>
            </b-card-text>
            <b-button id="book" :to="{ name: 'Book', params: { lang: this.$i18n.locale } }">{{ $t("menu.book") }}</b-button>
          </b-card>
          <b-card
            :img-src="require(`../../assets/fotos/triple1.jpg`)"
            img-alt="quadruple"
            img-top
            class="xs:mb-4 md:mb-0 xs:mx-0 md:mx-2"
          >
            <b-card-title class="cardtitle">{{
              $t("home.rooms.cuadru")
            }}</b-card-title>
            <b-card-text>
              <p>{{ $t("home.rooms.cuadrutext") }}</p>
            </b-card-text>
            <b-button id="book" :to="{ name: 'Book', params: { lang: this.$i18n.locale } }">{{ $t("menu.book") }}</b-button>
          </b-card> -->
        </div>
      </b-card-group>
  </div>
</template>

<script>
export default {
computed: {
    topRated() {
      return this.$store.state.topRated;
    },
  },
  methods: {
    addItem(items) {
      //   this.$store.commit("addToCart")
      this.$store.dispatch("addToCart", items);
    },
    removeItem(items) {
      this.$store.dispatch("removeItem", items);
    },
  },
}
</script>

<style>
.roomssectionhome{
  display: flex;
}
.cardgroup{
  display: flex;
  flex: 1;
}
.singlecard{
  flex: 1;
}
.cardtitle{
  margin-bottom: .75rem;
}
.priceroom{
  color: #a27035;
}

@media (min-width: 992px) {
  .cardgroup{
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .cardgroup>.singlecard{
    flex: 40%;
    margin-bottom: 10px;
  }
  .singlecard{
    margin: 0 6px;
  }
  .priceroom{
    font-weight: 700;
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  .cardgroup{
    flex-direction: row;
    flex-wrap: wrap;
  }
  .cardgroup>.singlecard{
    flex: 40%;
    margin-bottom: 10px;
  }
  .singlecard{
    margin: 2 8px;
  }
  .priceroom{
    font-weight: 700;
  }
}
/* small-tablet-portrait: min:600px - max:799px; */
@media (min-width: 600px) and (max-width: 799px) {
  .cardgroup{
    flex-direction: row;
    flex-wrap: wrap;
  }
  .cardgroup>.singlecard{
    flex: 30%;
    margin-bottom: 10px;
  }
  .singlecard{
    margin: 2px 5px;
  }
  .priceroom{
    font-weight: 700;
  }
}
/* mobile-landscape: min:480px - max:599px; */
@media (min-width: 480px) and (max-width: 599px) {
  .cardgroup{
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1rem;
  }
  .singlecard{
    margin-bottom: 1rem;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media (max-width: 479px) {
  .cardgroup{
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1rem;
  }
  .singlecard{
    margin-bottom: 1rem;
  }
}

</style>