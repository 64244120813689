<template>
  <div id="homecomponent" class="">
    <!-- <div class="carousel">
      <b-carousel
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000"
      background="#ababab"
        fade
        indicators
        img-width="1024"
        img-height="380"
      >
        <b-carousel-slide
          :img-src="require(`../../assets/fotos/carousel1.jpg`)"
        >
          <p id="slidestext1" class="text-3xl">{{ $t("menu.rooms") }}</p>
        </b-carousel-slide>
        <b-carousel-slide
          :img-src="require(`../../assets/fotos/carousel2.jpg`)"
        >
          <p id="slidestext1" class="text-3xl">{{ $t("menu.services") }}</p>
        </b-carousel-slide>
      </b-carousel>
    </div> -->

    <!-- Header -->
    <div class="headerb">
      <div class="textosbanner">
        <div class="text">
          <h1
            class="mosoqtitle"
            style="font-weight: bold; color: rgb(83, 62, 45)"
          >
            Mosoq Inn
          </h1>
          <p
            class="mosoqtitle2"
            style="color: rgb(83, 62, 45); margin-bottom: 5px"
          >
            "{{ $t("home.info.frase") }}"
          </p>
          <p class="mosoqp">{{ $t("home.info.welcome") }}</p>
          <b-button
            v-on:click="addItem(items)"
            id="book"
            :to="{ name: 'Book' }"
            >{{ $t("menu.book") }}</b-button
          >
        </div>
      </div>
    </div>

    <!-- Contact -->
    <div class="contacthome">
      <h2 class="titlesechome">{{ $t("contact.contact") }}</h2>
      <div class="infocontacthome">
        <div class="divcontact">
          <a
            id="linkcontact"
            href="https://www.facebook.com/mosoq.inn"
            target="_blank"
            class="itemcontact"
          >
            <i class="bx bxl-facebook-square pr-1" style="color: #a27035"></i>
            Facebook</a
          >
        </div>
        <div class="divcontact">
          <a
            id="linkcontact"
            href="https://www.instagram.com/mosoqinn/"
            target="_blank"
            class="itemcontact"
          >
            <i
              class="bx bxl-instagram pr-1"
              style="color: #a27035; margin-right: 2px"
            ></i>
            Instagram</a
          >
        </div>
        <div class="divcontact">
          <a
            id="linkcontact"
            class="itemcontact"
            href="https://wa.link/7b7y0r"
            target="_blank"
          >
            <i
              class="bx bxl-whatsapp px-1"
              style="color: #a27035; margin-right: 2px"
            ></i>
            +51 951 623 199</a
          >
        </div>
        <div class="divcontact">
          <i class="bx bx-envelope px-1" style="color: #a27035"></i>
          <a id="linkcontact" class="" target="_blank"
            >reservas@mosoqinnperu.com</a
          >
        </div>
        <div class="divcontact">
          <a
            id="linkcontact"
            class="itemcontact"
            href="https://www.google.com/maps/place/Mosoq+Inn+Hotel/@-15.8425985,-70.0256324,19z/data=!4m8!3m7!1s0x915d69934266e579:0xdbde5cba27081ffb!5m2!4m1!1i2!8m2!3d-15.8425985!4d-70.0250852"
            target="_blank"
          >
            <i
              class="bx bx-map px-1"
              style="color: #a27035; margin-right: 2px"
            ></i>
            Jr. Moquegua 673, Puno 21001 Perú</a
          >
        </div>
      </div>
    </div>
    <!-- Booking Area -->
    <!-- <div class="xs:px-8 md:px-4 py-3 bg-flax-light">
      <b-form class="flex place-content-center md:flex-row xs:flex-col">
        <div class="flex flex-col mx-3">
          <label class="label">{{ $t("home.booking.checkin") }}</label>
          <b-form-datepicker
            id="datepicker"
            v-model="checkin"
            :locale="this.$i18n.locale"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            :min="min"
            class=""
            placeholder=" "
          ></b-form-datepicker>
        </div>
        <div class="flex flex-col mx-3">
          <label class="label">{{ $t("home.booking.checkout") }}</label>
          <b-form-datepicker
            id="datepicker2"
            v-model="checkout"
            :locale="this.$i18n.locale"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            :min="min"
            class=""
            placeholder=" "
          ></b-form-datepicker>
        </div>
        <div class="flex flex-col mx-3">
          <label class="label">{{ $t("home.booking.people") }}</label>
          <b-input-group
            id="datepicker"
            class=""
            prepend-html='<i class="bx bxs-user" style="color:#533e2d"></i>'
          >
            <b-form-select v-model="adults" :options="options"></b-form-select>
          </b-input-group>
        </div>
        <b-button id="book" class="mx-3 mt-8">{{ $t("menu.book") }}</b-button>
      </b-form>
    </div> -->

    <!-- Amenities Area -->
    <div class="amenitieshome">
      <h2 class="titleshome">{{ $t("home.amenities.title") }}</h2>
      <p class="">{{ $t("home.amenities.p") }}</p>
      <div class="infoamenitieshome">
        <div class="amenitiesitem">
          <p class="itemcontact">
            <i class="bx bx-wifi" style="color: #a27035; margin-right: 5px"></i
            >Wifi
          </p>
        </div>
        <div class="amenitiesitem">
          <p class="itemcontact">
            <i class="bx bxs-car" style="color: #a27035; margin-right: 5px"></i>
            {{ $t("home.amenities.parking") }}
          </p>
        </div>
        <div class="amenitiesitem">
          <p class="itemcontact">
            <i
              class="bx bxs-backpack"
              style="color: #a27035; margin-right: 5px"
            ></i>
            {{ $t("home.amenities.luggage") }}
          </p>
        </div>
        <div class="amenitiesitem">
          <p class="itemcontact">
            <i
              class="bx bxs-user"
              style="color: #a27035; margin-right: 5px"
            ></i>
            {{ $t("home.amenities.attention") }}
          </p>
        </div>
        <div class="amenitiesitem">
          <p class="itemcontact">
            <i
              class="bx bxs-map-alt"
              style="color: #a27035; margin-right: 5px"
            ></i
            >{{ $t("home.amenities.info") }}
          </p>
        </div>
      </div>
    </div>

    <!-- Rooms Area -->
    <div class="roomshome">
      <h2 class="">{{ $t("home.rooms.title") }}</h2>
      <RoomsSection />
    </div>
  </div>
</template>

<script>
import RoomsSection from "@/components/Home/RoomsSection.vue";
export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    // minDate.setMonth(e.getMonth())
    // minDate.setDate()
    return {
      // locale: this.$i18n.locale,
      min: minDate,
      checkin: "",
      checkout: "",
      adults: null,
      options: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
      ],
    };
  },
  computed: {
    // checkin(){
    //   let checkindate = '';
    //   this.$store.state.checkin = checkindate;
    //   return this.checkin;
    // }
  },
  components: {
    RoomsSection,
  },
  // methods: {
  // Esto copiar en el check out
  // :date-disabled-fn="dateDisabled"
  //   dateDisabled(ymd, checkin) {
  //     // Disable weekends (Sunday = `0`, Saturday = `6`) and
  //     // disable days that fall on the 13th of the month
  //     const out = Date(checkin.getFullYear(), checkin.getMonth(), checkin.getDate())
  //     // const day = checkin.getDate()
  //     // Return `true` if the date should be disabled
  //     return out === 0
  //   }
  // }
};
</script>

<style>
#homecomponent {
  margin-top: 40px;
}
#slidestext1 {
  font-family: "Aladin", cursive;
}
#datepicker {
  color: #533e2d;
}
#datepicker2 {
  color: #b3a69c;
}
.label,
.cardtitle {
  font-family: "Aladin", cursive;
}

#linkcontact {
  color: #242331;
  text-decoration: none;
  font-family: "Rasa", serif;
}
.headerb {
  padding-top: 10px;
  display: flex;
  flex: 1;
}
.textosbanner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.text {
  text-align: center;
}
.mosoqtitle {
  font-size: 50px;
}
.mosoqtitle2 {
  font-size: 18px;
}
.contacthome,
.roomshome {
  background-color: #fffbe8;
  text-align: center;
}
.infoamenitieshome,
.infocontacthome {
  display: flex;
  flex: 1;
  justify-content: center;
}
.itemcontact {
  display: flex;
  align-items: center;
}
.amenitieshome {
  text-align: center;
}

@media (min-width: 992px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 0 40px;
  }
  .mosoqp {
    font-size: 20px;
  }
  .contacthome,
  .roomshome {
    padding: 15px;
  }
  .titlesechome {
    font-size: 29px;
  }
  .infoamenitieshome,
  .infocontacthome {
    flex-direction: row;
  }
  .itemcontact {
    margin: 0 1rem;
  }
  .amenitieshome {
    padding: 15px;
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 0 40px;
  }
  .mosoqp {
    font-size: 19px;
  }
  .contacthome,
  .roomshome {
    padding: 10px;
  }
  .titlesechome {
    font-size: 29px;
  }
  .infoamenitieshome,
  .infocontacthome {
    flex-direction: row;
  }
  .itemcontact {
    margin: 0 0.3rem;
  }
  .amenitieshome {
    padding: 20px;
  }
}
/* small-tablet-portrait: min:600px - max:799px; */
@media (min-width: 600px) and (max-width: 799px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 0 25px;
  }
  .mosoqp {
    font-size: 19px;
  }
  .contacthome,
  .roomshome {
    padding: 10px;
  }
  .titlesechome {
    font-size: 29px;
  }
  .infoamenitieshome,
  .infocontacthome {
    flex-direction: column;
    align-items: center;
  }
  .itemcontact {
    margin: 0 2px;
  }
  .amenitieshome {
    padding: 10px;
  }
}
/* mobile-landscape: min:480px - max:599px; */
@media (min-width: 480px) and (max-width: 599px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 0 25px;
  }
  .mosoqp {
    font-size: 19px;
  }
  .contacthome,
  .roomshome {
    padding: 20px;
  }
  .titlesechome {
    font-size: 29px;
  }
  .infoamenitieshome,
  .infocontacthome {
    flex-direction: column;
    align-items: center;
  }
  .itemcontact {
    margin: 0 2px;
  }
  .amenitieshome {
    padding: 20px;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 0 25px;
  }
  .mosoqp {
    font-size: 19px;
  }
  .contacthome,
  .roomshome {
    padding: 20px;
  }
  .titlesechome {
    font-size: 29px;
  }
  .infoamenitieshome,
  .infocontacthome {
    flex-direction: column;
    align-items: center;
  }
  .itemcontact {
    margin: 0 2px;
  }
  .amenitieshome {
    padding: 20px;
  }
}
</style>