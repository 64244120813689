<template>
  <div class="home">
    <HomeC/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeC from '@/components/Home/HomeC.vue'

export default {
  name: 'Home',
  components: {
    HomeC
  }
}
</script>
